import React, { useState, useEffect, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Components
import TextError from "../../../components/inputTextError/textError";

// Context
import { UserContext } from "../../../App";

// API
import { addServiceAPI, updateServiceByIdAPI } from "../../../api/services";

registerLocale("sr", sr);

const ServiceModal = (props) => {
  const { show, onHide, onUpdate, title, button, service } = props;
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState();

  const initialValues = {
    object: "",
    date: "",
    openBy: "",
    status: "",
    executors: "",
    description: "",
  };

  const validationSchema = Yup.object({
    object: Yup.string().required("Ovo polje je obavezno"),
    date: Yup.string().required("Ovo polje je obavezno"),
    description: Yup.string().required("Ovo polje je obavezno"),
    executors: Yup.string().required("Ovo polje je obavezno"),
  });

  const onSubmit = (serviceData, onSubmitProps) => {
    let dateTime = new Date(serviceData.date);
    serviceData.date = dateTime.toUTCString();
    if (title === "Nalog za servis") {
      addServiceAPI(serviceData)
        .then(() => {
          onHide();
          onUpdate();
        })
        .catch((error) => {
          toast.error(
            `Otvaranje naloga za servis nije uspelo zbog ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    } else if (title === "Izmeni podatke") {
      updateServiceByIdAPI(service._id, serviceData)
        .then(() => {
          onHide();
          onUpdate();
        })
        .catch((error) => {
          toast.error(
            `Izmena podataka za servis nije uspela zbog ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (service) {
      setFormValues({
        object: service.object,
        date: new Date(service.date),
        description: service.description,
        executors: service.executors,
        status: service.status,
        openBy: user && user.firstName + " " + user.lastName,
      });
    } else {
      setFormValues({
        object: "",
        date: "",
        description: "",
        executors: "",
        status: "Aktivan",
        openBy: user && user.firstName + " " + user.lastName,
      });
    }
  }, [service, user]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-form-service">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form autoComplete="off">
                  <div className="form-group">
                    <label htmlFor="object">Pogon/Mašina/Objekat</label>
                    <Field
                      type="text"
                      id="object"
                      name="object"
                      className="form-input"
                    />
                    <ErrorMessage name="object" component={TextError} />
                  </div>
                  <div className="form-group date">
                    <label htmlFor="date">Datum servisa</label>
                    <Field name="date">
                      {({ form, field }) => {
                        const { setFieldValue } = form;
                        const { value } = field;
                        return (
                          <DatePicker
                            id="date"
                            {...field}
                            timeInputLabel="Vreme:"
                            dateFormat="dd/MM/yyyy p"
                            locale="sr"
                            showTimeInput
                            selected={value}
                            onChange={(val) => setFieldValue("date", val)}
                          />
                        );
                      }}
                    </Field>
                    <ErrorMessage name="date" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Opis poslova</label>
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      rows="10"
                      className="form-input"
                    />
                    <ErrorMessage name="description" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="executors">Izvršioci</label>
                    <Field
                      as="textarea"
                      id="executors"
                      name="executors"
                      rows="4"
                      className="form-input"
                    />
                    <ErrorMessage name="executors" component={TextError} />
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={onHide}
                    >
                      Izađi
                    </button>
                    <button
                      type="submit"
                      disabled={
                        !formikProps.isValid || formikProps.isSubmitting
                      }
                      className="btn btn-success save"
                    >
                      {button}{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceModal;
