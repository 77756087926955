import React, { useContext } from "react";

// Context
import { UserContext } from "../../../App";

// Utils
import { getDateTimeFromString } from "../../../common/utils";

const ServiceItem = (props) => {
  const {
    service,
    current,
    index,
    openServiceDescription,
    closeService,
    deleteService,
    editService,
  } = props;
  const { user } = useContext(UserContext);

  return (
    <tr
      className={service.status === "Aktivan" ? "table-danger" : "table-active"}
    >
      <td>{(current - 1) * 10 + index + 1}</td>
      <td>{service.object}</td>
      <td>
        {
          <button
            className="table-button"
            onClick={() =>
              openServiceDescription(
                service.object,
                service.description,
                service.executors
              )
            }
          >
            Pogledaj
          </button>
        }
      </td>
      <td>{getDateTimeFromString(service.date)}</td>
      <td>{service.status}</td>
      <td>{service.openBy}</td>
      <td>
        {service.status === "Aktivan" && (
          <button
            disabled={
              user && user.firstName + " " + user.lastName !== service.openBy
            }
            type="button"
            className="table-action-button"
            onClick={() => closeService(service._id)}
          >
            Zatvori
          </button>
        )}{" "}
        {service.status === "Aktivan" && (
          <button
            disabled={
              user && user.firstName + " " + user.lastName !== service.openBy
            }
            onClick={() => editService(service)}
            type="button"
            className="table-action-button"
          >
            Izmeni
          </button>
        )}{" "}
        <button
          type="button"
          className="table-action-button"
          disabled={user && user.role !== "superAdmin"}
          onClick={() => deleteService(service._id)}
        >
          Obriši
        </button>
      </td>
    </tr>
  );
};

export default ServiceItem;
