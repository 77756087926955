import React from "react";
import Modal from "react-bootstrap/Modal";

const ServiceDescriptionModal = (props) => {
  const { show, onHide, object, description, executors } = props;
  const executorsList = executors
    ?.split("\n")
    ?.filter((executor) => executor !== "");
  const descriptionList = description
    ?.split("\n")
    ?.filter((description) => description !== "");

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Servis {object}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-service-body-header">Opis poslova:</div>
        <div>
          <ul>
            {descriptionList?.map((description) => (
              <li key={description}>{description}</li>
            ))}
          </ul>
        </div>
        <div className="modal-service-body-header">Izvršioci:</div>
        <div>
          <ol>
            {executorsList?.map((executor) => (
              <li key={executor}>{executor}</li>
            ))}
          </ol>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-info" onClick={onHide}>
          Zatvori
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceDescriptionModal;
