import React, { useContext } from "react";
import { UserContext } from "../../../App";

function PartItem(props) {
  const { current, index, part, openImageView, deletePart, editPart } = props;
  const { user } = useContext(UserContext);
  return (
    <tr className={part.quantity === 0 ? "table-parts-row-orange" : ""}>
      <td>{(current - 1) * 10 + index + 1}</td>
      <td>{part.description}</td>
      <td>{part.orderNumber}</td>
      <td>{part.manufacturer}</td>
      <td>{part.quantity}</td>
      <td>{part.objects}</td>
      <td>
        {part.imageURL ? (
          <img
            className="image-view"
            alt="Deo"
            src={part.imageURL}
            width="30"
            height="30"
            onClick={() => openImageView(part.imageURL)}
          />
        ) : (
          ""
        )}
      </td>
      <td>
        {/* .replace("upload", "upload/fl_attachment") */}
        {part.manualURL ? (
          <a href={part.manualURL} download title="Preuzmite">
            <i className="icon-pdf fas fa-file-pdf fa-2x"></i>
          </a>
        ) : (
          ""
        )}
      </td>
      <td>
        <button
          type="button"
          className="table-action-button"
          disabled={
            user && user.role !== "adminParts" && user.role !== "superAdmin"
          }
          onClick={() => editPart(part)}
        >
          Izmeni
        </button>
        <button
          type="button"
          className="table-action-button"
          disabled={
            user && user.role !== "adminParts" && user.role !== "superAdmin"
          }
          onClick={() => deletePart(part._id)}
        >
          Obriši
        </button>
      </td>
    </tr>
  );
}

export default PartItem;
