import React, { useState, useEffect, useContext, useReducer } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form } from "formik";
import { UserContext } from "../../../../App";
import Spinners from "../../../../components/spinners/spinners";
import Pagination from "../../../../components/pagination/pagination";
import ReportItem from "../report-item/report-item";
import { addReportAPI, getReportsAPI } from "../../../../api/reports";

const initialState = {
  loading: true,
  error: "",
  reports: [],
  page: {
    current: 1,
    limit: 10,
    totalPages: 0,
    totalDocuments: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS": {
      return {
        loading: false,
        error: "",
        reports: action.payload.content,
        page: action.payload.page,
      };
    }
    case "ERROR": {
      return {
        loading: false,
        error: action.payload,
        reports: [],
        page: {
          current: 1,
          limit: 10,
          totalPages: 0,
          totalDocuments: 0,
        },
      };
    }
    case "PAGE_CHANGE": {
      return initialState;
    }
    default:
      return state;
  }
};

function MotorReportModal(props) {
  const { show, position, conveyorName, serialNumber, onHide } = props;
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialValues = {
    report: "",
  };
  const [report, setReport] = useState(null);
  const [reportFormatError, setReportFormatError] = useState(false);
  const [positionText, setPositionText] = useState("");
  const posText = () => {
    switch (position) {
      case "GL":
        setPositionText("gornjem levom");
        break;
      case "GD":
        setPositionText("gornjem desnom");
        break;
      case "DL":
        setPositionText("donjem levom");
        break;
      case "DD":
        setPositionText("donjem desnom");
        break;
      default:
        setPositionText("");
    }
  };
  const getReports = (page = 1, limit = 10) => {
    let params = {
      searchBySerialNumber: serialNumber,
      page: page,
      limit: limit,
    };
    getReportsAPI(params).then((response) => {
      if (response.message) {
        dispatch({ type: "ERROR", payload: response.message });
        toast.error(
          "Dodavanje izveštaja o motoru u bazu nije uspelo zbog:",
          response.message,
          {
            containerId: "Error",
          }
        );
      } else {
        dispatch({ type: "SUCCESS", payload: { ...response.data } });
      }
    });
  };

  const resetForm = () => {
    setReport(null);
    onHide();
  };
  const onUploadReport = (event) => {
    const fileType = event.target.files[0].type;
    if (
      !fileType
        .substring(fileType.indexOf("application/") + 12)
        .match(/(pdf)$/i)
    ) {
      setReportFormatError(true);
    } else {
      setReportFormatError(false);
      setReport(event.target.files[0]);
    }
  };
  const onSubmit = (values, onSubmitProps) => {
    if (reportFormatError) {
      onSubmitProps.setSubmitting(false);
    } else {
      const formData = new FormData();
      formData.append("report", report);
      formData.append("conveyorName", conveyorName);
      formData.append("position", position);
      formData.append("date", new Date());
      formData.append("serialNumber", serialNumber);
      dispatch({ type: "PAGE_CHANGE" });
      addReportAPI(formData)
        .then(() => {
          getReports(1);
          setReport(null);
          onSubmitProps.setSubmitting(false);
        })
        .catch((error) => {
          toast.error(
            "Dodavanje izveštaja o motoru u bazu nije uspelo zbog:",
            error.response.data.message,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    }
  };

  const onPageChange = (page) => {
    window.scroll(0, 0);
    dispatch({ type: "PAGE_CHANGE" });
    getReports(page);
  };

  useEffect(() => {
    window.scroll(0, 0);
    posText();
    getReports();
    return () => {
      dispatch({ type: "PAGE_CHANGE" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const reportsList = () => {
    return state.reports.map((currentReport, index) => {
      return (
        <ReportItem
          report={currentReport}
          key={currentReport._id}
          index={index}
          current={state.page.current}
        />
      );
    });
  };

  return (
    <Modal
      show={show}
      onHide={resetForm}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Izveštaji o {positionText} motoru na transporteru{" "}
          {conveyorName.toUpperCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!state.loading ? (
          <div className="container-reports-list">
            <table className="table table-stripped table-bordered table-hover">
              <thead className="thead">
                <tr>
                  <th>Broj</th>
                  <th>Izveštaj</th>
                  <th>Datum</th>
                </tr>
              </thead>
              <tbody>{state.reports.length > 0 && reportsList()}</tbody>
            </table>
            {state.reports.length === 0 && (
              <div className="no-reports">Nema izveštaja u bazi za motor</div>
            )}
            <Pagination
              show={state.page.totalDocuments > 10}
              onChange={onPageChange}
              limit={state.page.limit}
              current={state.page.current}
              total={state.page.totalDocuments}
            />
          </div>
        ) : (
          <Spinners />
        )}
        <div className="container-form-report">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formikProps) => {
              return (
                <Form autoComplete="off">
                  <div className="form-group">
                    <label>IZVEŠTAJ</label>
                    <input
                      type="file"
                      name="report"
                      multiple={false}
                      className="form-input-file"
                      onChange={(event) => onUploadReport(event)}
                    />
                    {reportFormatError && (
                      <div className="error">
                        Izveštaj mora biti u formatu pdf-a
                      </div>
                    )}
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={resetForm}
                    >
                      Izađi
                    </button>
                    <button
                      type="submit"
                      disabled={
                        !report ||
                        reportFormatError ||
                        !formikProps.isValid ||
                        formikProps.isSubmitting ||
                        (user &&
                          user.role !== "adminDiagnostics" &&
                          user.role !== "superAdmin")
                      }
                      className="btn btn-success save"
                    >
                      Dodaj{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MotorReportModal;
