import React, { useEffect } from "react";

// Components
import Page from "../../components/page/page";

function Department() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <div className="container-department">
        <h1>Pogon "Tamnava - Istočno polje"</h1>
        <div className="management">
          <h3>
            <u>Operativa službe elektro održavanja</u>
          </h3>
          <div className="left-indent">
            <p>
              Upravnik elektro održavanja (28): <b>Radoje Mitrović</b>
            </p>
            <p>
              Pomoćnik upravnika elektro održavanja za opremu (29):{" "}
              <b>Nikola Vasiljević</b>
            </p>
            <p>
              Pomoćnik upravnika elektro održavanja za postrojenje pripreme i
              drobljenje uglja (30): <b>Nikola Tadić </b>
            </p>
            <p>
              Sistem administrator za sistem upravljanja kvalitetom uglja
              (30.a): <b>Ivan Ravić </b>
            </p>
            <p>
              Inženjer za održavanje sistema upravljanja kvalitetom uglja
              (30.b): <b>Filip Ivanović (PRO TENT)</b>
            </p>
            <p>
              Inženjer za održavanje zatvorenih izvora jonizujućeg zračenja i
              zaštitu od zračenja (30.b.1): <b>Žarko Drobnjak</b>
            </p>
            <p>
              Inženjer za održavanje zatvorenih izvora jonizujućeg zračenja i
              zaštitu od zračenja (30.b.1): <b></b>
            </p>
            <p>
              Tehničar za održavanje online analizatora pepela (30.v): <b></b>
            </p>
            <p>
              Tehničar za održavanje online analizatora pepela (30.v): <b></b>
            </p>
            <p>
              Tehničar za održavanje online analizatora pepela (30.v): <b></b>
            </p>
            <p>
              Tehničar u smeni za sistem upravljanja kvalitetom uglja (30.g):{" "}
              <b></b>
            </p>
            <p>
              Tehničar u smeni za sistem upravljanja kvalitetom uglja (30.g):{" "}
              <b></b>
            </p>
            <p>
              Tehničar u smeni za sistem upravljanja kvalitetom uglja (30.g):{" "}
              <b></b>
            </p>
            <p>
              Tehničar u smeni za sistem upravljanja kvalitetom uglja (30.g):{" "}
              <b></b>
            </p>
            <p>
              Tehničar u smeni za sistem upravljanja kvalitetom uglja (30.g):{" "}
              <b></b>
            </p>
          </div>
        </div>
        <div className="systems">
          <h3>
            <u>Elektro operativa pripreme uglja</u>
          </h3>
          <div className="left-indent">
            <p>
              Elektro inženjer u pripremi uglja (62): <b>Zoran Milošević</b>
            </p>
            <p>
              Poslovođa (107): <b></b>
            </p>
            <p>
              Smenski nadzornik (108): <b>Vladimir Milutinović</b>
            </p>
            <p>
              Smenski nadzornik (108): <b>Saša Paunović</b>
            </p>
            <p>
              Smenski nadzornik (108): <b>Milovan Simanović</b>
            </p>
            <p>
              Smenski nadzornik (108): <b>Saša Petrović</b>
            </p>
            <p>
              Smenski nadzornik (108): <b>Milorad Radović</b>
            </p>
            <p>
              Smenski električar za bagerodlagač RS PCE 35M (109):{" "}
              <b>Srđan Đokić</b>
            </p>
            <p>
              Smenski električar za bagerodlagač RS PCE 35M (109):{" "}
              <b>Raša Anđelković</b>
            </p>
            <p>
              Smenski električar za bagerodlagač RS PCE 35M (109):{" "}
              <b>Dragan Stojanović</b>
            </p>
            <p>
              Smenski električar za bagerodlagač RS PCE 35M (109): <b></b>
            </p>
            <p>
              Smenski električar za bagerodlagač RS PCE 35M (109): <b></b>
            </p>
            <p>
              Elektro tehničar u operativi (110): <b>Ružica Tomić</b>
            </p>
            <p>
              Električar (111): <b>Milan Arsenović</b>
            </p>
            <p>
              Električar (111): <b>Dragan Pošarac</b>
            </p>
            <p>
              Električar (111): <b>Blagoje Pavlović</b>
            </p>
            <p>
              Električar (111): <b>Predrag Pajić</b>
            </p>
            <p>
              Električar (111): <b>Duško Ranković</b>
            </p>
            <p>
              Električar (111): <b>Nikola Pavlović</b>
            </p>
            <p>
              Električar: (111) <b>Željko Marić</b>
            </p>
            <p>
              Električar (111): <b>Luka Mitrović</b>
            </p>
            <p>
              Električar (111): <b>Vladimir Perišić</b>
            </p>
            <p>
              Električar (111): <b>Saša Mićić</b>
            </p>
            <p>
              Električar (111): <b></b>
            </p>
            <p>
              Električar (112): <b>Nikola Ivković (PRO TENT)</b>
            </p>
            <p>
              Električar (112): <b>Stefan Filipović (PRO TENT)</b>
            </p>
            <p>
              Električar: <b>Sava Roksić (PRO TENT)</b>
            </p>
            <p>
              Električar (112): <b>Branko Ivković (PRO TENT)</b>
            </p>
            <p>
              Električar (112): <b></b>
            </p>
          </div>
        </div>
        <div className="departments">
          <h3>
            <u>Preventivno elektro odžavanje postrojenja za pripreme uglja</u>
          </h3>
          <div className="left-indent">
            <p>
              Rukovodilac održavanja postrojenja (357): <b></b>
            </p>
            <p>
              Poslovođa (358): <b>Radovan Vuković</b>
            </p>
            <p>
              Nadzornik (359): <b></b>
            </p>
            <p>
              Elektrotehničar (360): <b></b>
            </p>
            <p>
              Predradnik: (361) <b>Milorad Pantelić</b>
            </p>
            <p>
              Predradnik (361): <b></b>
            </p>
            <p>
              Predradnik (361): <b></b>
            </p>
            <p>
              Električar (362): <b></b>
            </p>
            <p>
              Električar (362): <b></b>
            </p>
            <p>
              Električar (362): <b></b>
            </p>
            <p>
              Električar (363): <b></b>
            </p>
            <p>
              Električar (363): <b></b>
            </p>
            <p>
              Električar (364): <b>Dragan Vujanović</b>
            </p>
            <p>
              Električar (364): <b></b>
            </p>
            <p>
              Električar (365): <b>Žarko Mitrović</b>
            </p>
            <p>
              Električar (365): <b>Milan Obradović</b>
            </p>
            <p>
              Električar (365): <b></b>
            </p>
            <p>
              Električar (366): <b>Predrag Vučić (PRO TENT)</b>
            </p>
            <p>
              Električar (366): <b></b>
            </p>
            <p>
              Električar (366): <b></b>
            </p>
            <p>
              Električar (367): <b>Slavica Marković</b>
            </p>
            <p>
              Električar (367): <b></b>
            </p>
            <p>
              Električar za pripremu rezervnih delova (368): <b>Sanja Đurić</b>
            </p>
            <p>
              Električar za pripremu rezervnih delova (368):{" "}
              <b>Biljana Marić</b>
            </p>
            <p>
              Pomoćni radnik (369): <b></b>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Department;
