import React, { useContext } from "react";
import { useHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../components/inputTextError/textError";
import { UserContext } from "../../../App";
import { updateUserByIdAPI } from "../../../api/users";

const options = [
  { value: "Administrator", label: "Administrator" },
  { value: "AdministratorParts", label: "Administrator za delove" },
  { value: "AdministratorDiagnostics", label: "Administrator za dijagnostiku" },
  { value: "AdministratorServices", label: "Administrator za servise" },
  { value: "AdministratorPowerSupply", label: "Administrator za napajanje" },
  { value: "GeneralUser", label: "Korisnik" },
  { value: "NewUser", label: "Novi Korisnik" },
];

function EditUserModal(props) {
  const { user, logoutUser } = useContext(UserContext);
  const history = useHistory();
  const { show, onHide, onUpdate, currentUser } = props;
  const initialValues = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    job: currentUser.job,
    email: currentUser.email,
    role: currentUser.role,
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    job: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Invalid email format"),
  });

  const onSubmit = (editUserData, onSubmitProps) => {
    updateUserByIdAPI(currentUser._id, editUserData)
      .then(() => {
        onHide();
        if ((user && user.role) !== "superAdmin") {
          logoutUser();
          history.push("/login");
        }
        onUpdate();
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error("Izmena podataka u bazi nije uspela", {
            containerId: "Error",
          });
          onSubmitProps.setSubmitting(false);
        }
      });
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Izmeni podatke</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-form-edit-user">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="form-group">
                    <label htmlFor="firstName">Ime</label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-input"
                    />
                    <ErrorMessage name="firstName" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Prezime</label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-input"
                    />
                    <ErrorMessage name="lastName" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="job">Radno mesto</label>
                    <Field
                      type="text"
                      id="job"
                      name="job"
                      className="form-input"
                    />
                    <ErrorMessage name="job" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="form-input"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="role">Rola</label>
                    <Field
                      disabled={user && user.role !== "superAdmin"}
                      as="select"
                      id="role"
                      name="role"
                    >
                      {options.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={onHide}
                    >
                      Izađi
                    </button>
                    <button
                      type="submit"
                      disabled={
                        !formikProps.isValid || formikProps.isSubmitting
                      }
                      className="btn btn-success save"
                    >
                      Izmeni{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditUserModal;
