// A wrapper for <Route> that redirects to the landing page if you're not authenticated
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

// Service
import AuthService from "./auth.service";

// Function
import { tokenRefresh } from "../security/token.function";

// Context
import { UserContext } from "../../App";

export default function PrivateRoute({ component: Component, ...properties }) {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...properties}
      render={(props) => {
        if (user && user.role === "newUser") {
          toast.warning(
            "Molimo vas da kao novi korisnik sačekate da vam administrator dodeli rolu.",
            {
              containerId: "Warning",
            }
          );
          return <Redirect to={{ pathname: "/" }} />;
        } else if (AuthService.isAuthenticated()) {
          if (!localStorage.getItem("tamnavaPortalTokenRefreshIsActive")) {
            localStorage.setItem("tamnavaPortalTokenRefreshIsActive", true);
            tokenRefresh();
          }
          return <Component {...props} />;
        } else {
          toast.warning("Morate da se prijavite na aplikaciju", {
            containerId: "Warning",
          });
          return <Redirect to={{ pathname: "/" }} />;
        }
      }}
    />
  );
}
