import React, { useContext } from "react";
import { UserContext } from "../../../App";

function UserItem(props) {
  const { current, index, currentUser, deleteUser, editUser } = props;
  const { user } = useContext(UserContext);
  return (
    <tr>
      <td>{(current - 1) * 10 + index + 1}</td>
      <td>{currentUser.firstName + " " + currentUser.lastName}</td>
      <td>{currentUser.job}</td>
      <td>{currentUser.email}</td>
      <td>
        <button
          disabled={
            currentUser.email !== (user && user.email) &&
            user &&
            user.role !== "superAdmin"
          }
          type="button"
          className="user-button"
          onClick={() => editUser(currentUser)}
        >
          Izmeni
        </button>
        <button
          disabled={user && user.role !== "superAdmin"}
          type="button"
          className="user-button"
          onClick={() => deleteUser(currentUser._id)}
        >
          Obriši
        </button>
      </td>
    </tr>
  );
}

export default UserItem;
