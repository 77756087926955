import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import TextError from "../../components/inputTextError/textError";
import Page from "../../components/page/page";
import { UserContext } from "../../App";
import { loginAPI } from "../../api/users";

function Login() {
  const { addUser } = useContext(UserContext);
  const history = useHistory();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Ovo polje je obavezno")
      .email("Nevažeči email format"),
    password: Yup.string()
      .required("Ovo polje je obavezno")
      .min(8, "Lozinka mora da ima minimum 8 karaktera"),
  });
  const onSubmit = (loginData, onSubmitProps) => {
    loginAPI(loginData)
      .then((response) => {
        localStorage.setItem(
          "tamnavaPortalLoginToken",
          response.data.accessToken
        );
        localStorage.setItem(
          "tamnavaPortalRefreshToken",
          response.data.refreshToken
        );
        addUser();
        history.push("/");
      })
      .catch((error) => {
        if (error.response.data.message === "Email does not exists in DB") {
          onSubmitProps.setFieldError(
            "email",
            "Email adresa ne postoji u bazi"
          );
        } else if (error.response.data.message === "Wrong password") {
          onSubmitProps.setFieldError("password", "Pogrešna lozinka");
        } else if (
          error.response.data.message &&
          error.response.data.message !== "Email does not exists in DB" &&
          error.response.data.message !== "Wrong password"
        ) {
          toast.error(
            "Prijava nije uspela zbog: ",
            error.response.data.message,
            {
              containerId: "Error",
            }
          );
        }
        onSubmitProps.setSubmitting(false);
      });
  };
  useEffect(() => {
    localStorage.clear();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <div className="container-form-login">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            return (
              <Form autoComplete="on">
                <h3>Prijavite se na svoj nalog</h3>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="form-input"
                    autoComplete="email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Lozinka</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="form-input"
                    autoComplete="current-password"
                  />
                  <ErrorMessage name="password" component={TextError} />
                </div>
                <button
                  type="submit"
                  disabled={!formikProps.isValid || formikProps.isSubmitting}
                  className="btn btn-success"
                >
                  Prijavite se{" "}
                  {formikProps.isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </Form>
            );
          }}
        </Formik>
        <p className="account-label">
          Nemate nalog na Portalu elektro službe TIP?
        </p>
        <Link to="/signup" className="btn btn-primary">
          Registrujte se
        </Link>
      </div>
    </Page>
  );
}

export default Login;
