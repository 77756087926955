import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../components/inputTextError/textError";
import { UserContext } from "../../../App";
import {
  addMarkerAPI,
  updateMarkerByIdAPI,
  deleteMarkerByIdAPI,
} from "../../../api/markers";
import { optionsCabinetType, optionsSubstations } from "../../../common/consts";

function MarkerModal(props) {
  const { show, onHide, onUpdate, button, marker } = props;
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState();
  const initialValues = {
    cabinetType: "NK",
    cabinetMark: "1",
    substation: "IP",
    powerLineNumber: "1",
  };
  const validationSchema = Yup.object({
    cabinetType: Yup.string().required("Ovo polje je obavezno"),
    substation: Yup.string().required("Ovo polje je obavezno"),
    powerLineNumber: Yup.string().required("Ovo polje je obavezno"),
  });
  const deleteMarker = () => {
    deleteMarkerByIdAPI(marker._id).then((response) => {
      if (response.message) {
        toast.error(
          `Brisanje markera u baze nije uspelo zbog: ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        onHide();
        onUpdate();
      }
    });
  };
  const onSubmit = (markerData, onSubmitProps) => {
    let data = {
      ...markerData,
      lat: marker.lat,
      lng: marker.lng,
      time: new Date(),
      addedBy: user.firstName + " " + user.lastName,
    };
    if (button === "Dodaj") {
      addMarkerAPI(data)
        .then(() => {
          onHide();
          onUpdate();
        })
        .catch((error) => {
          toast.error(
            `Dodavanje markera za napajanje nije uspelo zbog ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    } else if (button === "Izmeni") {
      updateMarkerByIdAPI(marker._id, data)
        .then(() => {
          onHide();
          onUpdate();
        })
        .catch((error) => {
          toast.error(
            `Izmena podataka za marker nije uspela zbog ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    }
  };
  useEffect(() => {
    if (marker && marker.cabinetType) {
      setFormValues({
        cabinetType: marker.cabinetType,
        cabinetMark: marker.cabinetMark,
        substation: marker.substation,
        powerLineNumber: marker.powerLineNumber,
      });
    }
  }, [marker]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Podaci</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-form-marker">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="form-group">
                    <label htmlFor="cabinetType">Tip</label>
                    <Field
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      as="select"
                      id="cabinetType"
                      name="cabinetType"
                    >
                      {optionsCabinetType.map((cabinetType) => {
                        return (
                          <option
                            key={cabinetType.value}
                            value={cabinetType.value}
                          >
                            {cabinetType.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="cabinetMark">Oznaka</label>
                    <Field
                      type="text"
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      id="cabinetMark"
                      name="cabinetMark"
                      className="form-input"
                    />
                    <ErrorMessage name="cabinetMark" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="substation">Trafo stanica</label>
                    <Field
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      as="select"
                      id="substation"
                      name="substation"
                    >
                      {optionsSubstations.map((substation) => {
                        return (
                          <option
                            key={substation.value}
                            value={substation.value}
                          >
                            {substation.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="powerLineNumber">Dalekovod</label>
                    <Field
                      type="text"
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      id="powerLineNumber"
                      name="powerLineNumber"
                      className="form-input"
                    />
                    <ErrorMessage
                      name="powerLineNumber"
                      component={TextError}
                    />
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={onHide}
                    >
                      Izađi
                    </button>
                    <button
                      type="button"
                      disabled={
                        formikProps.isSubmitting ||
                        (user &&
                          user.role !== "adminPowerSupply" &&
                          user.role !== "superAdmin")
                      }
                      className="btn btn-danger delete"
                      onClick={() => deleteMarker()}
                    >
                      Obriši
                    </button>
                    <button
                      type="submit"
                      disabled={
                        !formikProps.isValid ||
                        formikProps.isSubmitting ||
                        (user &&
                          user.role !== "adminPowerSupply" &&
                          user.role !== "superAdmin")
                      }
                      className="btn btn-success save"
                    >
                      {button}{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MarkerModal;
