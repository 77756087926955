const AuthorizationRole = (role) => {
  switch (role) {
    case "Administrator":
      return "superAdmin";
    case "AdministratorParts":
      return "adminParts";
    case "AdministratorServices":
      return "adminServices";
    case "AdministratorDiagnostics":
      return "adminDiagnostics";
    case "AdministratorPowerSupply":
      return "adminPowerSupply";
    case "GeneralUser":
      return "user";
    case "NewUser":
      return "newUser";
    default:
      return "";
  }
};

export default AuthorizationRole;
