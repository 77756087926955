import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../App";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../../components/inputTextError/textError";
import { addPartAPI, updatePartByIdAPI } from "../../../api/parts";

function PartModal(props) {
  const { show, onHide, onUpdate, part, title, button } = props;
  const { user } = useContext(UserContext);
  const [image, setImage] = useState(null);
  const [manual, setManual] = useState(null);
  const [imageFormatError, setImageFormatError] = useState(false);
  const [manualFormatError, setManualFormatError] = useState(false);
  const [formValues, setFormValues] = useState();
  const initialValues = {
    description: "",
    orderNumber: "",
    manufacturer: "",
    quantity: "",
    objects: "",
    image: null,
    manual: null,
  };
  const validationSchema = Yup.object({
    description: Yup.string().required("Ovo polje je obavezno"),
    orderNumber: Yup.string()
      .required("Ovo polje je obavezno")
      .matches(/^[^/]*$/g, "Ne možete da koristite /"),
    manufacturer: Yup.string().required("Ovo polje je obavezno"),
    quantity: Yup.number()
      .typeError("Nije broj")
      .integer("Nije ceo broj")
      .required("Ovo polje je obavezno"),
  });
  const resetForm = () => {
    onHide();
    setImage(null);
    setManual(null);
  };
  useEffect(() => {
    if (part) {
      setFormValues({
        description: part.description,
        orderNumber: part.orderNumber,
        manufacturer: part.manufacturer,
        quantity: part.quantity,
        objects: part.objects,
      });
      setImage(part.imageURL);
      setManual(part.manualURL);
    } else {
      setFormValues({
        description: "",
        orderNumber: "",
        manufacturer: "",
        quantity: "",
        objects: "",
        image: null,
        manual: null,
      });
    }
  }, [part]);
  const onUploadImage = (event) => {
    const fileType = event.target.files[0].type;
    if (
      !fileType
        .substring(fileType.indexOf("image/") + 6)
        .match(/(jpg|jpeg|png|gif)$/i)
    ) {
      setImageFormatError(true);
    } else {
      setImageFormatError(false);
      setImage(event.target.files[0]);
    }
  };
  const onUploadManual = (event) => {
    const fileType = event.target.files[0].type;
    if (
      !fileType
        .substring(fileType.indexOf("application/") + 12)
        .match(/(pdf)$/i)
    ) {
      setManualFormatError(true);
    } else {
      setManualFormatError(false);
      setManual(event.target.files[0]);
    }
  };
  const onSubmit = (values, onSubmitProps) => {
    if (imageFormatError || manualFormatError) {
      onSubmitProps.setSubmitting(false);
    } else {
      const formData = new FormData();
      formData.append("description", values.description);
      formData.append("orderNumber", values.orderNumber);
      formData.append("manufacturer", values.manufacturer);
      formData.append("quantity", values.quantity);
      formData.append("objects", values.objects);
      formData.append("image", image);
      formData.append("manual", manual);
      if (title === "Dodaj novi deo") {
        addPartAPI(formData)
          .then(() => {
            onHide();
            onUpdate();
          })
          .catch((error) => {
            if (
              error.response.data.message.includes(
                "E11000 duplicate key error collection"
              )
            ) {
              onSubmitProps.setFieldError(
                "orderNumber",
                "Porudžbeni kod već postoji u bazi podataka"
              );
            } else {
              toast.error(
                `Dodavanje dela u bazu nije uspelo zbog: ${error.response.data.message}`,
                {
                  containerId: "Error",
                }
              );
            }
            onSubmitProps.setSubmitting(false);
          });
      } else if (title === "Izmeni podatke") {
        updatePartByIdAPI(part._id, formData)
          .then(() => {
            onHide();
            onUpdate();
          })
          .catch((error) => {
            toast.error(
              `Izmena podataka u bazi nije uspela zbog: ${error.response.data.message}`,
              {
                containerId: "Error",
              }
            );
            onSubmitProps.setSubmitting(false);
          });
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={resetForm}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-form-create-part">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="form-group">
                    <label htmlFor="description">Opis</label>
                    <Field
                      as="textarea"
                      rows="6"
                      id="description"
                      name="description"
                      className="form-input"
                    />
                    <ErrorMessage name="description" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="orderNumber">Porudžbeni kod</label>
                    <Field
                      type="text"
                      id="orderNumber"
                      name="orderNumber"
                      className="form-input"
                    />
                    <ErrorMessage name="orderNumber" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="manufacturer">Proizvođač</label>
                    <Field
                      type="text"
                      id="manufacturer"
                      name="manufacturer"
                      className="form-input"
                    />
                    <ErrorMessage name="manufacturer" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="quantity">Količina</label>
                    <Field
                      type="text"
                      id="quantity"
                      name="quantity"
                      className="form-input"
                    />
                    <ErrorMessage name="quantity" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="objects">Objekti</label>
                    <Field
                      type="text"
                      id="objects"
                      name="objects"
                      className="form-input"
                    />
                    <ErrorMessage name="objects" component={TextError} />
                  </div>
                  <div className="form-group">
                    <label>Slika</label>
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      multiple={false}
                      className="form-input-file"
                      onChange={(event) => onUploadImage(event)}
                    />
                    {imageFormatError && (
                      <div className="error">
                        Slika mora biti u formatu: jpg|jpeg|png|gif
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Uputstvo</label>
                    <input
                      type="file"
                      name="manual"
                      className="form-input-file"
                      onChange={(event) => onUploadManual(event)}
                    />
                    {manualFormatError && (
                      <div className="error">
                        Uputstvo mora biti u formatu pdf-a
                      </div>
                    )}
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={resetForm}
                    >
                      Izađi
                    </button>
                    <button
                      type="submit"
                      disabled={
                        imageFormatError ||
                        manualFormatError ||
                        !formikProps.isValid ||
                        formikProps.isSubmitting ||
                        (user &&
                          user.role !== "adminParts" &&
                          user.role !== "superAdmin")
                      }
                      className="btn btn-success save"
                    >
                      {button}{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PartModal;
