import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";

// Context
import { UserContext } from "../../App";

const NavBar = () => {
  const { logoutUser } = useContext(UserContext);

  const logout = () => {
    localStorage.clear();
    logoutUser();
  };

  return (
    <div className="container-navbar">
      <div className="container no-padding">
        <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
          <NavLink to="/" className="navbar-brand">
            <img
              alt="logo"
              src="/images/logo.png"
              width="30px"
              height="30px"
              className="logo-spinner"
            />{" "}
            Portal TIP
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="navbar-item">
                <NavLink
                  to="/department"
                  className="nav-link"
                  activeClassName="active"
                >
                  Služba
                </NavLink>
              </li>
              {/* <li className="navbar-item">
                <NavLink
                  to="/maps"
                  className="nav-link"
                  activeClassName="active"
                >
                  Karta
                </NavLink>
              </li> */}
              {/* <li className="navbar-item">
                <NavLink
                  to="/lubrication"
                  className="nav-link"
                  activeClassName="active"
                >
                  Podmazivanje
                </NavLink>
              </li> */}
              <li className="navbar-item">
                <NavLink
                  to="/services"
                  className="nav-link"
                  activeClassName="active"
                >
                  Servisi
                </NavLink>
              </li>
              <li className="navbar-item">
                <NavLink
                  to="/parts"
                  className="nav-link"
                  activeClassName="active"
                >
                  Delovi
                </NavLink>
              </li>
              <li className="navbar-item">
                <NavLink
                  to="/users"
                  className="nav-link"
                  activeClassName="active"
                >
                  Korisnici
                </NavLink>
              </li>
              <li className="navbar-item">
                <Link to="/" className="nav-link" onClick={logout}>
                  Odjavite se
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
